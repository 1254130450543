import { BaseQueryParams } from "../baseQuery";

export const configurationService = BaseQueryParams("configService", [
  "CONFIGURATION_GENERAL",
  "CONFIGURATION_PAYMENT",
  "CONFIGURATION_OPTIONS",
  "PriceRules",
]).injectEndpoints({
  endpoints: (builder) => ({
    getConfigurationGeneral: builder.query({
      query: () => ({
        url: "/admin/configurations/general",
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["CONFIGURATION_GENERAL"],
    }),

    getConfigurationPayments: builder.query({
      query: () => ({
        url: "/admin/configurations/payments",
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["CONFIGURATION_PAYMENT"],
    }),

    getConfigurationOptions: builder.query({
      query: () => ({
        url: "/admin/configurations/migrations/options",
        method: "GET",
      }),

      keepUnusedDataFor: 0,
      providesTags: () => ["CONFIGURATION_OPTIONS"],
    }),

    getLogsNames: builder.query({
      query: () => ({
        url: "/admin/developers/logs/names",
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),

    getLogs: builder.query({
      query: ({ name, page }) => ({
        url: `/admin/developers/logs?${name ? `name=${name}` : ""}${page ? `&page=${page}` : ""}&config=1`,
        method: "GET",
      }),
    }),

    updateConfig: builder.mutation({
      query: ({ key, body }) => ({
        url: `/admin/configurations/${key}`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => [
        "CONFIGURATION_GENERAL",
        "CONFIGURATION_PAYMENT",
        "CONFIGURATION_OPTIONS",
      ],
    }),
  }),
});

export const {
  useGetConfigurationGeneralQuery,
  useGetConfigurationPaymentsQuery,
  useGetConfigurationOptionsQuery,
  useUpdateConfigMutation,
  useGetLogsNamesQuery,
  useGetLogsQuery,
} = configurationService;
