export const PATTERN_FROM_TO_PARAMS =
  /^(?<firstPrefix>from|to)[-](?<first>[a-z]+)(?:[-](to)(?:[-](?<second>[a-z]+))?)?(?:[-](?<preview>preview))?$/;

export function isValidFromTo(value) {
  const regex = /^(from|to)-[a-z]+(?:-to-[a-z]+)?(?:-preview)?$/;

  if (/^from-[a-z]+-preview$/.test(value)) {
    return false;
  }
  return regex.test(value);
}
